<template>
    <div class="w-100">
        <div
            class="d-flex flex-wrap flex-md-row flex-column-reverse align-items-end"
        >
            <div class="col-12 col-md-9 p-md-0 mx-auto">
                <div class="w-100 d-flex align-items-end">
                    <products-search
                        v-model="lastProductSelected"
                        class="mt-4"
                        :default-filters="defaultFilters"
                        @focus="onFocusSomeInput"
                        @blur="onBlurSomeInput"
                        @input="selectProduct"
                    />
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div
                    class="w-100 d-flex justify-content-center flex-lg-row flex-column-reverse"
                >
                    <products-barcode-scanner
                        v-model="isEnabledScanner"
                        @input="selectProduct"
                    />
                    <div class="d-flex justify-content-center">
                        <slot name="complement"></slot>
                    </div>
                </div>
            </div>
        </div>
        <vs-dialog
            v-model="openModalReferences"
            scroll
            overflow-hidden
            auto-width
            @close="isEnabledScanner = true"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                >
                    <span class="h4">
                        Seleccione la referencia del producto
                        <span class="text-primary">
                            {{ productTmp.name }}
                        </span>
                    </span>
                    <div>
                        <vs-button icon circle @click="pushReference">
                            ✔️
                        </vs-button>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <div class="d-flex flex-wrap w-100">
                    <div
                        v-for="(item, index) in productTmp.references"
                        :key="item.id"
                        class="col-10 col-md-5 col-lg-3 mx-auto p-2"
                    >
                        <button
                            class="d-flex flex-column btn btn-primary w-100"
                            :class="
                                referenceSelected === index
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectReference(index)"
                        >
                            <div>
                                <strong> Tamaño </strong>
                                <span class="text-capitalize">
                                    {{ item.size }}
                                </span>
                            </div>
                            <div>
                                <strong> Color </strong>
                                <span class="text-capitalize">
                                    {{ item.color }}
                                </span>
                            </div>
                            <div>
                                <strong> Precio </strong>
                                <span>
                                    {{ item.priceOffer | money }}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import ProductsBarcodeScanner from "@/components/products/ProductsBarcodeScanner.vue";
import ProductsSearch from "@/components/products/ProductsSearch.vue";

export default {
    name: "ReferencesSearchScanner",
    components: {
        ProductsSearch,
        ProductsBarcodeScanner
    },
    props: {
        defaultFilters: {
            default: () => ({}),
            type: Object,
            required: false
        },
        enabledScanner: {
            default: () => false,
            type: Boolean,
            required: false
        },
        any: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["input"],
    data: () => ({
        lastProductSelected: { name: "" },
        isEnabledScanner: false,
        openModalReferences: false,
        referenceSelected: null,
        productTmp: {
            id: 68,
            sku: "",
            slug: "",
            name: "",
            references: []
        }
    }),
    computed: {},
    watch: {
        enabledScanner(value) {
            this.isEnabledScanner = value && true;
        }
    },
    mounted() {
        this.isEnabledScanner = true;
        this.addHandlerKey();
    },
    beforeDestroy() {
        this.isEnabledScanner = false;
        this.removeHandlerKey();
    },
    methods: {
        async selectProduct(product) {
            this.productTmp = { ...product };
            if (product.references.length === 0) {
                await this.$swal.fire({
                    background: this.backgroundColor,
                    icon: "error",
                    title: "Producto invalido",
                    text: "Entra a los detalles del producto y verifica que tenga referencias"
                });
                window
                    .open(`/app/products/detail/${product.id}`, "_blank")
                    .focus();
            } else if (product.references.length === 1 || this.any) {
                this.validatePush(product.references[0], product);
                this.openModalReferences = false;
                this.referenceSelected = -1;
                this.isEnabledScanner = true;
            } else {
                this.isEnabledScanner = false;
                this.productTmp = { ...product };
                this.referenceSelected = -1;
                this.openModalReferences = true;
            }
        },
        validatePush(reference, product) {
            this.$emit("input", {
                ...reference,
                appliedDiscountPerReference: 0,
                productId: product.id,
                product: {
                    id: product.id,
                    sku: product.sku,
                    slug: product.slug,
                    name: product.name
                }
            });
            this.referenceSelected = -1;
        },
        pushReference() {
            if (this.referenceSelected < 0) return;
            const reference =
                this.productTmp.references[this.referenceSelected];
            this.validatePush(reference, this.productTmp);
            this.openModalReferences = false;
            this.referenceSelected = -1;
            this.isEnabledScanner = true;
        },
        selectReference(index) {
            if (index < 0) {
                return;
            }
            this.isEnabledScanner = false;
            this.referenceSelected = index;
        },
        incrementReferenceSelect() {
            this.isEnabledScanner = false;
            if (!this.openModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (
                this.referenceSelected <
                this.productTmp.references.length - 1
            ) {
                this.referenceSelected = this.referenceSelected + 1;
            }
        },
        decrementReferenceSelect() {
            this.isEnabledScanner = false;
            if (!this.openModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (this.referenceSelected > 0) {
                this.referenceSelected = this.referenceSelected - 1;
            }
        },
        onEnter() {
            if (this.openModalReferences) {
                this.pushReference();
            }
        },
        handleKey(event) {
            const actions = {
                Enter: this.onEnter,
                Escape: this.closeReferenceModal,
                ArrowUp: this.decrementReferenceSelect,
                ArrowDown: this.incrementReferenceSelect
            };
            const fun = actions[event.key] || function () {};
            fun();
        },
        closeReferenceModal() {
            this.openModalReferences = false;
            this.isEnabledScanner = true;
        },
        removeHandlerKey() {
            document.removeEventListener("keydown", this.handleKey);
        },
        addHandlerKey() {
            document.addEventListener("keydown", this.handleKey);
        },
        onBlurSomeInput() {
            this.isEnabledScanner = true;
            this.addHandlerKey();
        },
        onFocusSomeInput() {
            this.isEnabledScanner = false;
            this.removeHandlerKey();
        }
    }
};
</script>
