<template>
    <fragment>
        <div
            v-if="enabled"
            class="d-flex align-items-center justify-content-center"
        >
            <vs-switch
                v-model="enabledScanner"
                :class="enabledScanner ? 'bg-primary' : 'bg-secondary'"
                class="border border-dark bg-primary"
                @input="changeEnableScanner"
            >
                <template #off> Scanner off </template>
                <template #on> Scanner on </template>
            </vs-switch>

            <vs-tooltip border>
                <vs-button icon @click="openModal"> 📠 </vs-button>
                <template #tooltip>Ingreso de código de barras manual</template>
            </vs-tooltip>
        </div>
        <span v-else> Scanner off ⚪</span>

        <vs-dialog
            v-model="isOpenModalManual"
            overflow-hidden
            auto-width
            @close="closeModal"
        >
            <div class="scanner-container px-3 py-2">
                <span class="h4">Ingresa el código de barras</span>
                <div class="d-flex mt-2">
                    <vs-input
                        id="barcode"
                        v-model="barcode"
                        type="text"
                        name="barcode"
                        state="primary"
                        class="input-theme w-100 mr-2 border-custom"
                        aria-label="Barcode"
                        icon-after
                        :disabled="isLoading"
                        @keyup.enter="onClickSearch"
                    >
                    </vs-input>
                    <vs-button
                        circle
                        icon
                        :loading="isLoading"
                        @click="onClickSearch"
                    >
                        🔎
                    </vs-button>
                </div>
            </div>
        </vs-dialog>
    </fragment>
</template>

<script>
import { stringRegex } from "@/common/lib/regex";

export default {
    name: "BarcodeScanner",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => false,
            type: Boolean,
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change", "input"],
    data: () => ({
        enabledScanner: false,
        isLoading: false,
        wasActive: false,
        timer: null,
        barcode: "",
        isOpenModalManual: false
    }),
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.handleListener);
    },
    methods: {
        handleListener(event) {
            if (!this.enabledScanner || !stringRegex.test(event.key)) return;
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.barcode += `${event.key}`;
            this.timer = setTimeout(() => {
                if (this.barcode.length > 2) {
                    this.$emit("input", this.barcode);
                    this.isOpenModalManual = false;
                }
                this.barcode = "";
            }, 20);
        },
        startListener() {
            document.addEventListener("keydown", this.handleListener);
        },
        stopListener() {
            document.addEventListener("keydown", this.handleListener);
        },
        changeEnableScanner() {
            this.$emit("change", this.enabledScanner);
        },
        openModal() {
            this.isOpenModalManual = true;
            this.enabledScanner = false;
            this.stopListener();
        },
        closeModal() {
            this.enabledScanner = true;
            this.startListener();
        },
        async onClickSearch() {
            try {
                this.$emit("input", this.barcode);
                this.isOpenModalManual = false;
                this.closeModal();
            } catch (error) {
                this.isOpenModalManual = true;
                this.barcode = "";
            }
        },
        setInput(value) {
            this.enabledScanner = Boolean(value) && !this.$isPhone;
            if (value) {
                this.startListener();
            } else {
                this.stopListener();
            }
        }
    }
};
</script>
