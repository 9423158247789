<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/carts"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                    <div
                        v-if="id"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h1 class="h2 text-center">
                            &nbsp;Detalle de Carrito
                            <span class="text-primary"> #{{ id }} </span>
                        </h1>
                        <vs-tooltip>
                            <vs-button icon border @click="copyTo">
                                🖨
                            </vs-button>
                            <template #tooltip>
                                <div class="content-tooltip">
                                    <span> Copiar en portapapeles </span>
                                </div>
                            </template>
                        </vs-tooltip>
                    </div>

                    <h1 v-else>&nbsp; Registrar carro de compras</h1>
                </div>

                <vs-switch
                    v-if="$ability.hasScope('supplyList:Update') && id"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4">
                <p>
                    Recuerda debe de haber inventario en la tienda seleccionada
                    para poder encontrar el carro de compras pre fabricado.
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="name"
                    border
                    shadow
                    type="text"
                    icon-after
                    label="Nombre de carrito"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                    :state="stateEnabled"
                    @focus="onFocusSomeInput"
                    @blur="onBlurName"
                >
                    <template #icon> 🔑 </template>
                    <template
                        v-if="!isValidName & introValidators.name"
                        #message-danger
                    >
                        Nombre de Carrito invalido
                    </template>
                </vs-input>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    id="begin"
                    v-model="expiredAt"
                    border
                    shadow
                    type="date"
                    class="w-100 mw-100 opacity-false border-custom"
                    label="Fecha de expiración"
                    :disabled="!enabledUpdate"
                    :state="stateEnabled"
                    name="trip-begin"
                    :min="dateMax"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 mt-5 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Vendedor </strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip>
                            Se registran las ventas generadas por el carrito de
                            compras al vendedor.
                        </template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="seller.email"
                    border
                    shadow
                    :state="stateEnabled"
                    disabled
                    class="w-100 opacity-false"
                />
            </div>
            <div class="col-12 col-md-6 mt-5 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>
                        {{ merchant.merchantType | merchantInfo("type") }}
                    </strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip>
                            {{
                                enabledUpdate
                                    ? "Puedes cambiar la tienda seleccionandola en la parte superior"
                                    : "Tienda a la que se debe recoger el pedido"
                            }}
                        </template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="merchant.name"
                    border
                    shadow
                    class="w-100 opacity-false"
                    :state="stateEnabled"
                    disabled
                >
                </vs-input>
            </div>
        </div>

        <div
            v-show="enabledUpdate"
            class="row mt-5 flex-column justify-content-center align-items-center"
        >
            <h3>Selecciona las referencias del carrito</h3>
            <references-search-scanner
                :enabled-scanner="enabledScanner"
                :default-filters="{ active: true }"
                @input="validatePushReferences"
            />
        </div>

        <div class="z-0 mt-3">
            <h4 v-show="references.length" class="mx-2 mt-3">
                Precio de Referencias
                <strong class="text-primary">
                    {{ total | money }}
                </strong>
            </h4>
            <reference-table-items
                v-for="(reference, index) in references"
                :key="index"
                :enabled="enabledUpdate"
                :reference="reference"
                class="col-md-11 col-12 mx-auto"
                @update="updateReferenceOfCart"
                @remove="removeReference"
                @focus="onFocusSomeInput"
            />
        </div>

        <div v-if="references.length > 0 && enabledUpdate" class="row z-0 my-4">
            <div class="col-12 d-flex justify-content-center">
                <vs-button v-if="!id" size="large" @click="createItemComponent">
                    Crear carrito
                </vs-button>
                <vs-button v-else @click="updateItemComponent">
                    Actualizar carrito
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";

import { nameRegex } from "@/common/lib/regex";

import ReferenceTableItems from "@/components/references/ReferenceTableItems.vue";
import ReferencesSearchScanner from "@/components/references/ReferencesSearchScanner.vue";

export default {
    name: "CartsDetails",
    components: {
        ReferenceTableItems,
        ReferencesSearchScanner
    },
    data: () => ({
        dateMax: DateTime.now().toFormat("yyyy-MM-dd"),
        id: 0,
        total: 0,
        name: "",
        expiredAt: null,
        references: [],
        seller: { email: "" },
        merchant: { name: "", merchantId: "", merchantType: "store" },
        enabledScanner: false,
        loaderInstance: null,
        enabledUpdate: false,
        introValidators: {
            name: false
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        ...mapState("auth", ["user"]),
        isValidName() {
            return nameRegex.test(this.itemComponent.name);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {
        selectedMerchant({ id, name, type }) {
            if (this.enabledUpdate) {
                this.merchant = {
                    name,
                    merchantId: id,
                    merchantType: type
                };
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("cart:Get", "/app/carts"))) return;
            const id = Number(this.$route.params.id);
            this.id = id;
            await this.getBaseItem(id);
        } else if ("idClone" in this.$route.params) {
            if (!(await verifyPageScope("cart:Create", "/app/carts"))) return;
            this.id = 0;
            this.enabledUpdate = true;
            const idTmp = Number(this.$route.params.idClone);
            await this.getBaseItem(idTmp);
            this.name = `${this.name} CLONE`;
            const { merchantId, merchantType, name } = this.selectedMerchant;
            this.merchant = { merchantId, merchantType, name };
            this.seller.email = this.user.email;
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando un carro de compras",
                icon: "info",
                showCloseButton: true
            });
        } else {
            await verifyPageScope("cart:Create", "/app/carts");
            this.seller.email = this.user.email;
            const { merchantId, merchantType, name } = this.selectedMerchant;
            this.merchant = { merchantId, merchantType, name };
            this.enabledUpdate = true;
            this.enabledScanner = true;
        }
    },
    methods: {
        ...mapActions("carts", ["getCartById", "updateCart", "createCart"]),
        async getBaseItem(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "rectangle" });
            try {
                const { expiredAt, merchant, name, references, seller } =
                    await this.getCartById({ id });
                this.expiredAt = expiredAt;
                this.name = name;
                this.merchant = merchant;
                this.seller = seller;
                this.references = [...references];
                this.calculateTotals();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/carts");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateItemComponent() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Carrito invalido",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "rectangle" });
            try {
                await this.updateCart(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createItemComponent() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Carrito invalido",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "rectangle" });
            try {
                const data = await this.createCart(this.getDataSend());
                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `El enlace a sido copiado a tu porta papeles: ${this.$marketplaceUrl}/checkout/${data.id}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de carritos",
                    cancelButtonText: "Nuevo Carrito"
                });
                this.id = data.id;
                this.copyTo();
                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.$router.push(`/app/carts/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/carts/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        name: false
                    };
                    this.name = "";
                    this.references = [];
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async copyTo() {
            await navigator.clipboard.writeText(
                `${this.$marketplaceUrl}/checkout/${this.id}`
            );
            this.$vs.notification({
                title: "Copiado al porta papeles",
                color: "success",
                icon: "✅"
            });
        },
        validateData() {
            this.introValidators = {
                name: true
            };
            return this.isValidName && this.references.length;
        },
        calculateTotals() {
            this.total = this.references.reduce(
                (a, b) => a + b.priceOffer * b.quantity,
                0
            );
        },
        getDataSend() {
            return {
                id: this.id,
                name: this.name,
                merchantId: this.merchant.merchantId,
                merchantType: this.merchant.merchantType,
                expiredAt: this.expiredAt,
                references: this.references.map((item) => ({
                    id: item.id,
                    quantity: item.quantity
                }))
            };
        },
        validatePushReferences(reference) {
            const index = this.references.findIndex(
                (item) => item.id === reference.id
            );

            if (index === -1) {
                this.references.unshift({ ...reference, quantity: 1 });
            } else {
                let references = [...this.references];
                references[index] = {
                    ...references[index],
                    quantity: references[index].quantity + 1
                };
                this.references = [...references];
            }
            this.calculateTotals();
        },
        updateReferenceOfCart({ appliedDiscountPerReference, id, quantity }) {
            this.onBlurSomeInput();
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references[index] = {
                ...references[index],
                quantity,
                appliedDiscountPerReference
            };
            this.references = [...references];
            this.calculateTotals();
        },
        removeReference({ id }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references.splice(index, 1);
            this.references = [...references];
            this.calculateTotals();
        },
        onBlurName() {
            this.introValidators.name = true;
            this.onBlurSomeInput();
        },
        onBlurSomeInput() {
            this.enabledScanner = true;
        },
        onFocusSomeInput() {
            this.enabledScanner = false;
        }
    }
};
</script>
