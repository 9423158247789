<template>
    <barcode-scanner
        v-model="enabledScanner"
        :enabled="enabled"
        @input="searchByBarcode"
        @change="changeEnableScanner"
    />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BarcodeScanner from "@/components/utils/BarcodeScanner.vue";

export default {
    name: "ProductsBarcodeScanner",
    components: { BarcodeScanner },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => false,
            type: Boolean,
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change", "input"],
    data: () => ({
        enabledScanner: false,
        isLoading: false
    }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        ...mapActions("products", ["getProductByBarcode"]),
        async searchByBarcode(barcode) {
            this.isLoading = true;
            this.keySearch = "";
            try {
                const productsAux = await this.getProductByBarcode({ barcode });
                // TODO: Validate if return many products
                this.$emit("input", productsAux);
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setInput(value) {
            this.enabledScanner = Boolean(value);
        },
        changeEnableScanner() {
            this.$emit("change", this.enabledScanner);
        }
    }
};
</script>
